function maxFrom(list) {
    let index = 0,
        lastVal = list[index];
    for(let i = 1; i < list.length; i++) {
        if (list[i] >= lastVal) {
            lastVal = list[i];
            index = i;
        }
    }
    return {val: lastVal, index};
}

export default maxFrom;