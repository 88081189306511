import '../../styles/card/card-title.css';

import { useState } from 'react';
import Icon from '../utils/Icon';
import TitleEditor from './TitleEditor';

function CardTitle(props) {
    const [editingTitle, setEditingTitle] = useState(false);

    const handleConfirmEditTitle = newTitleValue => {
        props.onConfirmEditTitle(newTitleValue);
        setEditingTitle(false);
    }

    const handleEditTitleBtnClick = (e) => {
        if (!props.isComparing)
            setEditingTitle(true);
    }

    return (
        <div className='card-title'>
            {editingTitle
            ?
            <TitleEditor title={props.title}
                onCancelEditTitle={() => setEditingTitle(false)}
                onConfirmEditTitle={handleConfirmEditTitle}
                onRemoveChoiceClick={props.onRemoveChoiceClick} />
            :
            <div className='title-container' onClick={handleEditTitleBtnClick}>
                <span className='layout-control'></span>
                <span>{props.title}</span>
                {props.isComparing ? props.isWinner ?
                    <span className="result-icon winner-icon" title='Do it!'>✔</span> :
                    <span className="result-icon not-winner-icon" title="Don't do it!">✖</span> :
                    <div className="title-controls">
                        <button className='edit-title-btn' title='Edit title'
                            onClick={() => setEditingTitle(true)}>
                            <Icon name="pencil" width="15" height="15" />
                        </button>
                        <button className='edit-title-btn remove-choice-btn' title='Remove choice' 
                            onClick={props.onRemoveChoiceClick}>
                            <Icon name="x-lg" width="15" height="15" />
                        </button>
                    </div>}
            </div>
            }
        </div>
    );
}

export default CardTitle;