import '../../styles/content/new-item-controls.css';

import ItemEditorBtnControls from './ItemEditorBtnControls';
import Weight from './Weight';
import { useState } from 'react';

function ItemEditorControls(props) {
    const [itemWeight, setItemWeight] = useState(props.weight || 5);

    return (
            <div className='new-item-controls'>
                <div>
                    <Weight weight={itemWeight} showControls={true} onUpdateWeight={newWeight => setItemWeight(newWeight)} />
                </div>

                <ItemEditorBtnControls
                    onConfirmItemClick={() => props.onConfirmItemClick(itemWeight)}
                    onCancelItemClick={props.onCancelItemClick}
                    onDeleteItemClick={props.onDeleteItemClick} />
            </div>
    );
}

export default ItemEditorControls;