import '../../styles/card/card-footer.css';

import { useState } from 'react';

function CardFooter(props) {
    const [isScoreInfoVisible, setIsScoreInfoVisible] = useState(false);
    
    return (
        <div className='card-footer'>
            {
            (props.isComparing && props.isMultipleChoice) &&
                <div className='score-container'>
                    <p className='total-score'>Score : {props.totalScore}</p>
                    <div className="score-explainer"
                        onMouseEnter={() => setIsScoreInfoVisible(true)}
                        onMouseLeave={() => setIsScoreInfoVisible(false)}>
                        <button className='info-marker'
                            onFocus={() => setIsScoreInfoVisible(true)}
                            onBlur={() => setIsScoreInfoVisible(false)}>?</button>
                        { isScoreInfoVisible && 
                        <p className='score-explanation'>
                            SCORE = PROS - CONS
                        </p>}
                    </div>
                </div>
            // :
            //     !props.isComparing &&
            //     <div className='card-footer-controls'>
            //         <button className='card-theme-btn'>
            //             <Icon name="pallete" width='20' height='20' />
            //         </button>
            //     </div>
            }
        </div>
    );
}

export default CardFooter;