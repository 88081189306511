import '../../styles/document/document-name-editor.css';

import { useEffect, useRef, useState } from 'react';
import Icon from '../utils/Icon';

function DocumentNameEditor({ name, handleCancelEditName, updateNameHandler, deleteDocumentHandler }) {
    const [newDocumentName, setNewDocumentName] = useState(name);
    const titleInputRef = useRef(null);

    const cancelEditName = () => {
        setNewDocumentName('');
        handleCancelEditName();
    }

    useEffect(() => {
        const titleInputElement = titleInputRef.current;

        const handleKeyUp = (e) => {
            if (e.code === 'Enter') {
                updateNameHandler(newDocumentName);
            }
        }
        titleInputElement.addEventListener('keyup', handleKeyUp);

        return () => {
            if (titleInputElement) {
                titleInputElement.removeEventListener('keyup', handleKeyUp)
            }
        }
    });

    return (
        <div className='editing-document-name-container'>
            <input
                ref={titleInputRef}
                type='text'
                placeholder='Document title'
                className='editing-document-name-input'
                value={newDocumentName}
                onChange={(e) => setNewDocumentName(e.target.value)} />
            <div className='editing-document-name-controls'>
                <button
                    className='remove-document'
                    title='Delete document'
                    onClick={deleteDocumentHandler}>
                    <Icon name="trash" width="17" height="17" />
                </button>
                <button
                    className='cancel-edit-document-name'
                    title='Cancel'
                    onClick={cancelEditName}>
                    <Icon name="x" width="20" height="20" />
                </button>
                <button
                    className='confirm-edit-document-name'
                    title='Confirm'
                    onClick={() => updateNameHandler(newDocumentName)}>
                    <Icon name="check" width="20" height="20" />
                </button>
            </div>
        </div> 
    );

}

export default DocumentNameEditor;