import '../../styles/content/content-body.css';

import Item from './Item';
import ItemEditor from './ItemEditor';

function ContentList(props) {
    const listItems = props.content.map(obj =>
        <Item key={obj.id} content={obj.content} weight={obj.weight} itemId={obj.id}
                onUpdateItem={props.onUpdateItem}
                onDeleteItemClick={props.onDeleteItemClick}
                isComparing={props.isComparing} />
    );

    return (
        <div className='content-body'>
            <ol>
                {listItems}
                <ItemEditor visible={props.newItemFormVisible}
                    onConfirmItemClick={props.onConfirmAddItemClick}
                    onCancelItemClick={props.onCancelAddItemClick} />
            </ol>
        </div>
    );
}

export default ContentList;