class Choice {
    static currentProsId = 0;
    static currentConsId = 0;

    constructor(title = 'Title', pros = [], cons = []) {
        this.title = title;

        this.pros = new Map();
        pros.forEach(pro => {
            let id = ++Choice.currentProsId;
            this.pros.set(id, {...pro, id});
        });

        this.cons = new Map();
        cons.forEach(con => {
            let id = ++Choice.currentConsId;
            this.cons.set(id, {...con, id});
        });
    }

    addPro(content = '', weight = 5) {
        let id = ++Choice.currentProsId;
        let newItem = {
            content,
            weight,
            id
        }
        this.pros.set(id, newItem);
    }
    addCon(content = '', weight = 5) {
        let id = ++Choice.currentConsId;
        let newItem = {
            content,
            weight,
            id
        }
        this.cons.set(id, newItem);
    }

    getPros(id) {
        if (id) {
            return this.pros.get(id);
        }

        let prosList = [];
        this.pros.forEach(pro => prosList.push(pro));

        return prosList;
    }

    getCons(id) {
        if (id) {
            return this.cons.get(id);
        }

        let consList = [];
        this.cons.forEach(con => consList.push(con));
        
        return consList;
    }

    getTotalScore() {
        const pros = this.getPros();
        const cons = this.getCons();
        const prosScore = pros.reduce((acc, item) => acc + item.weight, 0);
        const consScore = cons.reduce((acc, item) => acc + item.weight, 0);
        return (prosScore - consScore);
    }
}

export default Choice;