import '../../styles/content/item-controls.css';

import Weight  from './Weight';

function ItemControls(props) {
    return (
        <div className='controls'>
            <Weight weight={props.weight} onWeightIncrementClick={props.onWeightIncrementClick}
                    onUpdateWeight={props.onUpdateWeight} isComparing={props.isComparing} />
        </div>
    );
}

export default ItemControls;