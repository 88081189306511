import '../../styles/card/card.css';

import CardTitle from './CardTitle';
import CardBody from './CardBody';
import CardFooter from './CardFooter';

function Card(props) {
    let prosScore = 0;
    let consScore = 0;
    let totalScore = 0;
    let cardClassList = 'card';
    let isWinner = props.isWinner;

    if (props.isComparing) {
        prosScore = props.pros.reduce((acc, item) => acc + item.weight, 0);
        consScore = props.cons.reduce((acc, item) => acc + item.weight, 0);
        totalScore = prosScore - consScore;

        // negative score on single choice
        if (!props.isMultipleChoice && totalScore < 0)
            isWinner = false;

        //  uncomment this code to highlight winner
        // if (props.isMultipleChoice && totalScore < 0) {
        //     cardClassList += ' opacity-75';
        // }
    }

    return (
        <div className='card-container'
            style={{
                maxWidth: props.isSingle ? '640px' : '596px'
            }}>
            <div className={cardClassList}>
                <CardTitle title={props.title}
                    onConfirmEditTitle={(newTitleValue) => props.onConfirmEditTitle(props.id ,newTitleValue)}
                    onRemoveChoiceClick={() => props.onRemoveChoiceClick(props.id)}
                    isComparing={props.isComparing} isWinner={isWinner} />

                <CardBody pros={props.pros} cons={props.cons} prosScore={prosScore} consScore={consScore}
                    isComparing={props.isComparing} isMultipleChoice={props.isMultipleChoice}

                    onUpdateItem={(itemType, itemId, value, weight) => props.onUpdateItem(props.id, itemType, itemId, value, weight)}

                    onConfirmAddItemClick={(itemType ,itemValue, itemWeight) => props.onConfirmAddItemClick(props.id , itemType ,itemValue, itemWeight)}
                    onDeleteItemClick={(itemType, itemId) => props.onDeleteItemClick(props.id, itemType, itemId)} />

                <CardFooter isComparing={props.isComparing} isMultipleChoice={props.isMultipleChoice} totalScore={totalScore} />
            </div>
        </div>
    );
    
}

export default Card;