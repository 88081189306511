import Icon from "../utils/Icon";

function OpenSidebarMenuBtn({ openMenuHandler }) {
    
    return (
        <button
            className="open-menu-btn"
            onClick={openMenuHandler}>
            <Icon name="list" width="26" height="26" />
        </button>
    );
}

export default OpenSidebarMenuBtn;