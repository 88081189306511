import '../../styles/content/content.css';

import ContentTitle from'./ContentTitle';
import ContentList from'./ContentList';
import ContentFooter from'./ContentFooter';
import { useEffect, useState } from 'react';

function Content(props) {
    const [newItemFormVisible, setNewItemFormVisible] = useState(props.content.length === 0);

    // Show new item form when no items left
    useEffect(() => {
        setNewItemFormVisible(props.content.length === 0)
    }, [props.content.length]);

    const handleConfirmAddItemClick = (itemValue, itemWeight) => {
        resetAddItemForm();
        props.onConfirmAddItemClick(itemValue, itemWeight);
    }

    const handleCancelAddItemClick = () => {
        if(props.content.length > 0)
            resetAddItemForm();
    }
    
    const resetAddItemForm = () => {
        setNewItemFormVisible(false);
    }

    let contentClassList = 'content';
    //  uncomment this code to highlight winner
    // if (props.isComparing && !props.isWinner) {
    //     contentClassList += ' opacity-75';
    // }

    return (
        <div className={contentClassList}>
            <div>
                <ContentTitle title={props.title} />
                <ContentList content={props.content} newItemFormVisible={newItemFormVisible}
                        onConfirmAddItemClick={handleConfirmAddItemClick}
                        onCancelAddItemClick={handleCancelAddItemClick}
                        onDeleteItemClick={props.onDeleteItemClick}
                        onUpdateItem={props.onUpdateItem}
                        isComparing={props.isComparing} />
            </div>

            <ContentFooter newItemFormVisible={newItemFormVisible} 
                onAddItemClick={() => setNewItemFormVisible(true)} isComparing={props.isComparing}
                score={props.score} />
        </div>
    );
}

export default Content;