import '../styles/main-choice-actions.css';

import Icon from './utils/Icon';

function MainChoiceActions(props) {

    return (
        <div className='actions-container'>
            <div className='actions'>
                {props.isComparing ?
                    <button className="edit-choice-btn" onClick={props.handleEditClick}>Edit</button>
                :
                <>
                    <button className='compare-btn' onClick={props.onCompareClick}>
                        <Icon name="compare-arrows" width='24' height='24' />&nbsp;&nbsp;Compare
                    </button>
                    <button className='add-choice-btn' title='Add new option' onClick={props.onAddChoiceClick}>
                        <Icon name="plus-square" width='24' height='24' />&nbsp;&nbsp;Add option
                    </button>
                </> }
            </div>
        </div>
    );
}

export default MainChoiceActions;