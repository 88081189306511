import '../../styles/card/title-editor.css';

import { useEffect, useRef, useState } from 'react';
import Icon from '../utils/Icon';

function TitleEditor(props) {
    const [newTitleValue, setNewTitleValue] = useState(props.title);
    const titleInputRef = useRef(null);

    useEffect(() => {
        const titleInputElement = titleInputRef.current;

        const handleKeyUp = (e) => {
            if (e.code === 'Enter') {
                props.onConfirmEditTitle(newTitleValue);
            }
        }
        titleInputElement.addEventListener('keyup', handleKeyUp);

        return () => {
            if (titleInputElement) {
                titleInputElement.removeEventListener('keyup', handleKeyUp)
            }
        }
    });

    return (
        <div className='editing-title-container'>
            <input
                ref={titleInputRef}
                type='text'
                placeholder='Option title'
                className='editing-title-input'
                value={newTitleValue}
                onChange={(e) => setNewTitleValue(e.target.value)} />
            <div className='editing-title-controls'>
                <button className='delete-choice-btn' title='Delete option' onClick={props.onRemoveChoiceClick}>
                    <Icon name="trash" width="17" height="17" />
                </button>
                <button className='cancel-edit-title' title='Cancel' onClick={props.onCancelEditTitle}>
                    <Icon name="x" width="20" height="20" />
                </button>
                <button className='confirm-edit-title' title='Confirm' onClick={() => props.onConfirmEditTitle(newTitleValue)}>
                    <Icon name="check" width="20" height="20" />
                </button>
            </div>
        </div>
    );
}

export default TitleEditor;