import '../../styles/sidebar/sidebar.css';

import Icon from '../utils/Icon';

function Sidebar({
    documents,
    selectedDocId,
    opened,
    closeMenuHandler,
    newDocumentHandler,
    selectDocHandler
}) {
    const docList = documents.map(doc => (
        <li key={doc.id}
            className={`document-item ${doc.id === selectedDocId ? 'selected' : ''}`}
            onClick={() => {
                if (doc.id !== selectedDocId) {
                    selectDocHandler(doc.id);
                    closeMenuHandler();
                }
            }}>
            {doc.title}
        </li>
    ));

    return (
        <div className="sidebar-outer-container"
            style={{
                left: opened ? '0' : '-120%'
            }}>
            <div className="sidebar-container">
                <button
                    className="close-menu-btn"
                    onClick={closeMenuHandler}>
                    <Icon name="list" width="26" height="26" />
                </button>
                <div className="documents-list-container">
                    <button className="new-document-btn"
                        onClick={newDocumentHandler}>New Document</button>
                    <ul className="documents-list">
                        { docList }
                    </ul>
                </div>
            </div>
            <div className="bg-drop" onClick={closeMenuHandler}></div>
        </div>
    );
}

export default Sidebar;