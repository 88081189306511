import '../../styles/card/card-body.css';

import Content from '../content/Content';

function CardBody(props) {
    return (
        <div className='card-body'>
            <Content title="PROS" content={props.pros} score={props.prosScore} isWinner={props.prosScore >= props.consScore}
                isComparing={props.isComparing} isMultipleChoice={props.isMultipleChoice}

                onDeleteItemClick={itemId => props.onDeleteItemClick('pro', itemId)}

                onUpdateItem={(itemId, value, weight) => props.onUpdateItem('pro', itemId, value, weight)}

                onConfirmAddItemClick={(itemValue, itemWeight) => props.onConfirmAddItemClick('pro', itemValue, itemWeight)}
                onEditTitleClick={props.onEditTitleClick} />

            <Content title="CONS" content={props.cons} score={props.consScore} isWinner={props.consScore >= props.prosScore}
                isComparing={props.isComparing} isMultipleChoice={props.isMultipleChoice}

                onDeleteItemClick={itemId => props.onDeleteItemClick('con', itemId)}

                onUpdateItem={(itemId, value, weight) => props.onUpdateItem('con', itemId, value, weight)}

                onConfirmAddItemClick={(itemValue, itemWeight) => props.onConfirmAddItemClick('con', itemValue, itemWeight)}
                onEditTitleClick={props.onEditTitleClick} />
        </div>
    );
}

export default CardBody;