import '../../styles/content/new-item-btn-controls.css';

import Icon from '../utils/Icon';

function ItemEditorBtnControls(props) {
    return (
        <div className='new-item-btn-controls'>
            {/* don't show delete item btn when creating new item */}
           {props.onDeleteItemClick &&
            <button className='delete-item' title='Delete argument' onClick={props.onDeleteItemClick}>
                <Icon name="trash" width="17" height="17" />
            </button>}
            <button className='cancel-new-item' title='Cancel' onClick={props.onCancelItemClick}>
                <Icon name="x" width="20" height="20" />
            </button>
            <button className='confirm-new-item' title='Confirm' onClick={props.onConfirmItemClick}>
                <Icon name="check" width="20" height="20" />
            </button>
        </div>
    );
}

export default ItemEditorBtnControls;