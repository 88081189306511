import '../../styles/content/content-footer.css';

import Icon from '../utils/Icon';

function ContentFooter(props) {
    return (
        <>
            {props.isComparing ?
            <div className='total-weight-container'>
                <span className='total-weight-label'>Total weight:&nbsp;</span>
                <span className='total-weight'>{props.score}</span>
            </div>
            : !props.newItemFormVisible ?
            <div className='content-footer'>
                <button className='add-btn' title='Add new argument' onClick={props.onAddItemClick}>
                    <Icon name="plus" width="24" height="24" />
                </button>
            </div>
            :
            <div className='content-footer'>
            </div>
            }
        </>
    );
}

export default ContentFooter;