import '../../styles/document/document-name.css';

import { useState } from 'react';
import DocumentNameEditor from './DocumentNameEditor';

function DocumentName({ name, updateNameHandler, deleteDocumentHandler }) {
    const [editingDocumentName, setEditingDocumentName] = useState(false);

    const handleUpdateName = (newName) => {
        setEditingDocumentName(false);
        updateNameHandler(newName);
    }

    const handleDeleteDoc = () => {
        setEditingDocumentName(false);
        deleteDocumentHandler();
    }

    return (
        <div className="document-name-container">
       { editingDocumentName ?
            <DocumentNameEditor
                name={name}
                updateNameHandler={handleUpdateName}
                handleCancelEditName={() => setEditingDocumentName(false)}
                deleteDocumentHandler={handleDeleteDoc} />
            :
            <div className="document-name-container">
                <p className="document-name" onClick={() => setEditingDocumentName(true)}>
                    <span>{name}</span>
                    <span className="save-notice"> / Saved</span>
                </p>
            </div> }
        </div>
    );
}

export default DocumentName;