import '../../styles/content/weight.css';

import Icon from "../utils/Icon";

function Weight(props) {
    const handleWeightUpdate = (newWeight) => {
        if (newWeight >= 1 && newWeight <= 10) {
            props.onUpdateWeight(newWeight);
        }
    }
    
    let classList = 'weight';
    if (props.showControls)
        classList += ' showControls';
    if (!props.isComparing)
        classList += ' enabled';

    return (
        <div className='weight-container'>
            <div className={classList}>
                <button className='weight-inc'
                    onClick={(e) => {
                        if (!props.isComparing) {
                            e.stopPropagation(); // prevent item click
                            handleWeightUpdate(props.weight + 1)
                        }
                    }}>
                    <Icon name="caret-up" width={20} height={20} />
                </button>
                <span className='weight-value'>{props.weight}</span>
                <button className='weight-dec'
                    onClick={(e) => {
                        if (!props.isComparing) {
                            e.stopPropagation(); // prevent item click
                            handleWeightUpdate(props.weight - 1)
                        }
                    }}>
                    <Icon name="caret-down" width={20} height={20} />
                </button>
            </div>
        </div>
    );
}

export default Weight;