import DocumentName from "./document/DocumentName";
import OpenSidebarMenuBtn from "./sidebar/OpenSidebarMenuBtn";


function Header({ openMenuHandler, documentName, updateNameHandler, deleteDocumentHandler }) {
    return (
        <header className="header">
            <OpenSidebarMenuBtn
                openMenuHandler={openMenuHandler} />
            <DocumentName
                name={documentName}
                updateNameHandler={updateNameHandler}
                deleteDocumentHandler={deleteDocumentHandler} />
        </header>
    );
}

export default Header;