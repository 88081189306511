import maxFrom from '../utils/Utils';

class ChoiceList {
    static choices = [];
    static currentChoiceId = 0;
    static PRO_TYPE = 'pro';
    static CON_TYPE = 'con';

    static addChoice(newChoice) {
        const choice = newChoice;
        choice.id = ++ChoiceList.currentChoiceId;
        ChoiceList.choices.push(choice);
        return choice;
    }

    static editChoiceTitle(choiceId, newChoiceTitle) {
        const filteredChoices = ChoiceList.choices.filter(choice => choice.id === choiceId);

        if (filteredChoices.length > 0) {
            const targetChoice = filteredChoices[0];
            targetChoice.title = newChoiceTitle;
        }
    }

    static addChoiceItem(choiceId, itemType, value, weight) {
        const filteredChoices = ChoiceList.choices.filter(choice => choice.id === choiceId);

        if (filteredChoices.length > 0) {
            const targetChoice = filteredChoices[0];

            if (itemType === ChoiceList.PRO_TYPE) {
                targetChoice.addPro(value, weight);
            } else {
                targetChoice.addCon(value, weight);
            }
        }
    }

    static updateChoiceItem(choiceId, itemType, itemId, value, weight) {
        const filteredChoices = ChoiceList.choices.filter(choice => choice.id === choiceId);

        if (filteredChoices.length > 0) {
            const targetChoice = filteredChoices[0];
            const targetItem = itemType === ChoiceList.PRO_TYPE ? targetChoice.pros.get(itemId) : targetChoice.cons.get(itemId);
            
            if (targetItem !== undefined) {
                targetItem.content = value;
                targetItem.weight = weight;

                if (itemType === ChoiceList.PRO_TYPE) {
                    targetChoice.pros.set(itemId, targetItem);
                } else {
                    targetChoice.cons.set(itemId, targetItem);
                }
            }
        }
    }

    static deleteChoiceItem(choiceId, itemType, itemId) {
        const filteredChoices = ChoiceList.choices.filter(choice => choice.id === choiceId);

        if (filteredChoices.length > 0) {
            const targetChoice = filteredChoices[0];
            const targetItem = itemType === ChoiceList.PRO_TYPE ? targetChoice.pros.get(itemId) : targetChoice.cons.get(itemId);
            
            if (targetItem !== undefined) {
                if (itemType === ChoiceList.PRO_TYPE) {
                    targetChoice.pros.delete(itemId);
                } else {
                    targetChoice.cons.delete(itemId);
                }
            }
        }
    }

    static removeChoice(choiceId) {
        const filteredChoices = ChoiceList.choices.filter(choice => choice.id !== choiceId);
        ChoiceList.choices = filteredChoices;
    }

    static getWinners() {
        const winners = [];
        const scores = this.choices.map(choice => choice.getTotalScore());
        const winnerScore = maxFrom(scores).val;
        scores.forEach((score, i) => {
            if (score >= winnerScore)
                winners.push(this.choices[i].id); // index in scores in the same in choices
        })
        return winners;
    }
}

export default ChoiceList;