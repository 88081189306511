import './App.css';

import Main from './components/Main';
import Sidebar from './components/sidebar/Sidebar';
import { useEffect, useState } from 'react';

const demoDocument = {
  id: 0,
  title: `Social Media`,
  choices: [
    {
      title: 'Quit using social media',
      pros: [
        {
          content: 'I can focus on in-person communications and socialising.',
          weight: 4
        },
        {
          content: 'Invest the time spent on social media in more beneficial ways (reading books, going out, sports, ...).',
          weight: 7
        },
        {
          content: 'Better health (less sleep issues, less depression, less distraction).',
          weight: 8
        }
      ],
      cons: [
        {
          content: "Staying in touch with friends would be more difficult.",
          weight: 5
        },
        {
          content: "I have to find new ways to get news and know what's happening in the world.",
          weight: 3
        },
        {
          content: 'I might be missing some career opportunities.',
          weight: 4
        },
        {
          content: 'I will miss the entertaining part of social media.',
          weight: 2
        }
      ]
    }
  ]
}

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [documents, setDocuments] = useState([]);
  const [isComparing, setIsComparing] = useState(false);

  const createNewDocument = () => {
    setIsComparing(false);
    let docIdPointer = parseInt(window.localStorage.getItem('doc_id_pointer'));
    if(documents.filter(doc => doc.id === docIdPointer).length > 0)
      docIdPointer++;
    window.localStorage.setItem('doc_id_pointer', docIdPointer);

    const newDocument = {
      id: docIdPointer,
      title: `Document #${docIdPointer}`,
      choices: [
        {
          title: 'Choice Title',
          pros: [],
          cons: []
        }
      ]
    }

    setSelectedDocument(newDocument);
    setDocuments(docs => [newDocument, ...docs]);
  }

  const saveDocsToStorage = (docs) => {
    try {
      const strDocuments = JSON.stringify(docs);
      window.localStorage.setItem('documents', strDocuments);
    } catch (error) {
      console.error(error);
    }
  }

  const updateDocuments = (docData = selectedDocument) => {
    let newDocuments;
    setDocuments(oldDocs => {
      // other docs are not updated, so keep them
      newDocuments = oldDocs.map(doc => {
        if (doc.id === docData.id) {
          return docData;
        } else {
          return doc;
        }
      });
      saveDocsToStorage(newDocuments);
      return newDocuments;
    });
  }

  const updateDocTitle = (newTitle) => {
    setSelectedDocument(oldSelectedDocument => {
      return { ...oldSelectedDocument, title: newTitle };
    });
  }

  const selectDocHandler = (docId) => {
    const docToSelect = documents.filter(doc => doc.id === docId)[0];
    if (docToSelect)
      setSelectedDocument(docToSelect);
  }

  const handleDeleteDocument = () => {
    let newDocuments = documents.filter(doc => doc.id !== selectedDocument.id);
    if (newDocuments.length === 0) {
      createNewDocument();
      return;
    }
    setDocuments(newDocuments);
    setSelectedDocument(newDocuments[0]);
    saveDocsToStorage(newDocuments);
  }

  useEffect(() => {
    const loadDocuments = () => {
      const storedDocuments = window.localStorage.getItem('documents');
      if (storedDocuments === null || storedDocuments === "[]") {
        setDocuments([demoDocument]);
        setSelectedDocument(demoDocument);
        return;
      }
      try {
        const parsedDocuments = JSON.parse(storedDocuments);
        setDocuments(parsedDocuments);
        setSelectedDocument(parsedDocuments[0]);
      } catch (error) {
        console.error(error);
      }
    }

    const checkDocIdPointer = () => {
      const storedDocIdPointer = parseInt(window.localStorage.getItem('doc_id_pointer'));
      if (!storedDocIdPointer) {
        window.localStorage.setItem('doc_id_pointer', 1);
      }
    }

    checkDocIdPointer();
    loadDocuments();
  }, []);

  useEffect(() => {
    if(selectedDocument?.id)
      updateDocuments();
    // eslint-disable-next-line
  }, [selectedDocument]);

  return (
    selectedDocument ?
    <div className="App">
      <div className="main-group-container">
        <Sidebar
          documents={documents}
          selectedDocId={selectedDocument.id}
          opened={isMenuOpen}
          closeMenuHandler={() => setIsMenuOpen(false)}
          newDocumentHandler={createNewDocument}
          selectDocHandler={selectDocHandler} />
        <Main
          isComparing={isComparing}
          changeIsComparing={(newValue => setIsComparing(newValue))}
          selectedDocument={selectedDocument}
          updateDocTitleHandler={updateDocTitle} 
          openMenuHandler={() => setIsMenuOpen(true)}
          handleUpdateDocument={updateDocuments}
          deleteDocumentHandler={handleDeleteDocument} />
      </div>
    </div>
    : null
  );
}

export default App;
