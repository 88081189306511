import '../../styles/content/new-item-input.css';

function NewItemInput(props) {
    return (
        <textarea
            className='new-item-input'
            rows={3}
            value={props.value}
            placeholder="What is your argument?"
            onChange={props.onChange}>
        </textarea>
    );
}

export default NewItemInput;