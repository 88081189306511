// import '../../styles/content/new-item-container.css';

import ItemInput from './ItemInput';
import ItemEditorControls from './ItemEditorControls';
import { useState } from 'react';

function ItemEditor(props) {
    const [itemValue, setItemValue] = useState(props.value);

    const handleConfirmItemClick = itemWeight => {
        props.onConfirmItemClick(itemValue, itemWeight);
        setItemValue('');
    }
    return (
        props.visible &&
        <li className='new-item-container'>
            <ItemInput onChange={(e) => setItemValue(e.currentTarget.value)} value={itemValue} />
            <ItemEditorControls weight={props.weight}
                    onConfirmItemClick={handleConfirmItemClick}
                    onCancelItemClick={props.onCancelItemClick}
                    onDeleteItemClick={props.onDeleteItemClick} />
        </li>
    );
}

export default ItemEditor;