import '../../styles/content/content-text.css';

import { useState } from 'react';
import ItemControls from './ItemControls';
import ItemEditor from './ItemEditor';

function Item(props) {
    const [editingItem, setEditingItem] = useState(false);

    const handleConfirmItemClick = (itemValue, itemWeight) => {
        setEditingItem(false);
        props.onUpdateItem(props.itemId, itemValue, itemWeight)
    }

    const handleItemClick = (e) => {
        if (!props.isComparing)
            setEditingItem(true);
    }

    return (
        editingItem
        ?
        <ItemEditor value={props.content} weight={props.weight} visible={editingItem}
            onConfirmItemClick={handleConfirmItemClick}
            onCancelItemClick={() => setEditingItem(false)}
            onDeleteItemClick={() => props.onDeleteItemClick(props.itemId)} />
        :    
        <li className="item" onClick={handleItemClick}>
            <div className='container'>
                <p className='content-text'>{props.content}</p>
                <ItemControls weight={props.weight}
                        onEditItemClick={() => setEditingItem(true)}
                        onUpdateWeight={(newWeight) => props.onUpdateItem(props.itemId, props.content, newWeight)}
                        isComparing={props.isComparing} />
            </div>
        </li>
    );
}

export default Item;