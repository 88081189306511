import { useEffect, useState } from 'react';
import '../styles/main.css';

import Card from './card/Card';
import MainChoiceActions from './MainChoiceActions';
import Choice from './utils/Choice';
import ChoiceList from './utils/ChoiceList';
import Header from './Header';

function Main({
    selectedDocument,
    openMenuHandler,
    updateDocTitleHandler,
    handleUpdateDocument,
    deleteDocumentHandler,
    isComparing,
    changeIsComparing
}) {
    const [cardList, setCardList] = useState([]);

    const getCardList = () => {
        let winners = ChoiceList.getWinners();
        const cards = ChoiceList.choices.map(choice => {
            let isWinner = winners.indexOf(choice.id) >= 0;
            return <Card id={choice.id} key={choice.id} title={choice.title} pros={choice.getPros()}
                    onConfirmEditTitle={handleConfirmEditTitle}
                    cons={choice.getCons()} isComparing={isComparing} isMultipleChoice={ChoiceList.choices.length > 1}
                    onRemoveChoiceClick={handleRemoveChoiceClick}
                    onDeleteItemClick={handleDeleteItemClick}
                    onUpdateItem={handleUpdateItem}
                    onConfirmAddItemClick={handleConfirmAddItemClick}
                    isWinner={isWinner}
                    isSingle={ChoiceList.choices.length === 1} />
        });
        return cards;
    }

    const handleRemoveChoiceClick = choiceId => {
        ChoiceList.removeChoice(choiceId);
        handleUpdateDocument(exportDocumentToJSON());
        setCardList(getCardList());
    }

    const handleDeleteItemClick = (choiceId, itemType, itemId) => {
        ChoiceList.deleteChoiceItem(choiceId, itemType, itemId);
        handleUpdateDocument(exportDocumentToJSON());
        setCardList(getCardList());
    }

    const handleUpdateItem = (choiceId, itemType, itemId, value, weight) => {
        ChoiceList.updateChoiceItem(choiceId, itemType, itemId, value, weight);
        handleUpdateDocument(exportDocumentToJSON());
        setCardList(getCardList());
    }

    const handleConfirmAddItemClick = (choiceId, type, value, weight) => {
        ChoiceList.addChoiceItem(choiceId, type, value, weight);
        handleUpdateDocument(exportDocumentToJSON());
        setCardList(getCardList());
    }

    const handleConfirmEditTitle = (choiceId, newTitleValue) => {
        ChoiceList.editChoiceTitle(choiceId, newTitleValue);
        handleUpdateDocument(exportDocumentToJSON());
        setCardList(getCardList());
    }

    const handleAddChoiceClick = () => {
        const choice = new Choice();
        ChoiceList.addChoice(choice);
        handleUpdateDocument(exportDocumentToJSON());
        setCardList(getCardList());
    }

    const exportDocumentToJSON = () => {
        const document = {
            id: selectedDocument.id,
            title: selectedDocument.title,
            choices: ChoiceList.choices.map(choice => {
                const choiceToExport = {
                    title: choice.title,
                    pros: choice.getPros(),
                    cons: choice.getCons()
                }
                return choiceToExport;
            })
        }

        return document;
    }

    useEffect(() => {
        ChoiceList.choices = [];
        selectedDocument.choices?.forEach(choiceItem => {
            const choice = new Choice(choiceItem.title, choiceItem.pros, choiceItem.cons);
            ChoiceList.addChoice(choice);
        });
        setCardList(getCardList());
        // eslint-disable-next-line
    }, [selectedDocument]);

    useEffect(() => {
        setCardList(getCardList());
        // eslint-disable-next-line
    }, [isComparing]);

    return (
        <div className="main">
            <Header
                documentName={selectedDocument.title}
                updateNameHandler={updateDocTitleHandler}
                openMenuHandler={openMenuHandler}
                deleteDocumentHandler={deleteDocumentHandler} />
            <div className="main-container">
                <div className='choice-list'>
                    {cardList}
                </div>
                <MainChoiceActions
                onAddChoiceClick={handleAddChoiceClick}
                isComparing={isComparing}
                onCompareClick={() => {
                    if (ChoiceList.choices.length > 0)
                        changeIsComparing(true);
                }}
                handleEditClick={() => changeIsComparing(false)} />
            </div>

        </div>
    );
}

export default Main;